#contactPage {
    min-height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 60rem;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    overflow-x: hidden;
}

.contactPageTitle{
    font-size: 3rem;
    margin-bottom: 1rem;
}

.technologyDesc{
    font-weight: 300;
    font-size: 1rem;
}

.technologyImgs {
    margin: 1rem 0;
    display: flex;
    flex-wrap: wrap;
    max-height: 25rem; /* Sesuaikan dengan tinggi maksimal yang diinginkan */
    overflow-y: auto;
    scrollbar-width: thin;
}

.technologyImgs::-webkit-scrollbar {
    width: 5px; /* Lebar scrollbar */
}

.technologyImgs::-webkit-scrollbar-thumb {
    background-color: #888; /* Warna thumb (bagian yang bisa digerakkan) */
    border-radius: 5px; /* Membuat thumb sedikit bulat */
}

.technologyImgs::-webkit-scrollbar-track {
    background-color: #eee; /* Warna track (area di luar thumb) */
}

.technologyImg {
    object-fit: cover;
    width: 21%;
    min-width: 9rem;
    margin: auto;
    padding: 0.25rem;
}

#technologies{
    padding: 2rem;
}

.contactDesc {
    padding: 1rem;
    font-size: medium;
    font-weight: 300;
}

.contactForm {
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90vw;
    max-width: 60rem;
}

#contact {
    overflow: hidden;
}

.name, .email, .msg {
    font-size: medium;
    width: 100%;
    max-width: 40rem;
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    color: white;
    border: none;
    border-radius: 0.5rem;
    background: rgb(40, 40, 40);
}

.submitBtn {
    background: white;
    border: none;
    border-radius: 0.5rem;
    margin: 2rem;
    padding: 0.75rem 3.5rem;
    transition: all 0.3s ease;
}

.submitBtn:hover {
    background: rgb(59, 127, 245); /* Warna latar berubah saat dihover */
    color: white; /* Warna teks berubah saat dihover */
    cursor: pointer;
}

.links {
    display: flex;
    flex-wrap: wrap;
}

.link {
    object-fit: cover;
    height: 3rem;
    width: 3rem;
    margin: 0 0.75rem;
}

.technologyImgContainer {
    position: relative;
    width: 23%;
    min-width: 9rem;
    margin: auto;
    padding: 0.25rem;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .technologyImgContainer:hover .overlay {
    opacity: 1;
  }

  /* clients section */
  #clients {
    padding: 2rem;
}

.clientDesc {
    font-weight: 300;
    font-size: 1rem;
}

.clientImgs {
    margin: 1rem 0;
    display: flex;
    flex-wrap: wrap;
    max-height: 25rem; /* Sesuaikan dengan tinggi maksimal yang diinginkan */
    overflow-y: auto;
    text-align: center; /* Tambahkan properti text-align di sini */
}

.clientImgContainer {
    position: relative;
    width: 23%;
    min-width: 9rem;
    margin: auto;
    padding: 0.25rem;
}

.clientImg {
    object-fit: cover;
    width: 100%; /* Buat ukuran gambar menjadi 100% */
    height: auto; /* Sesuaikan tinggi secara otomatis */
    margin: auto;
    padding: 0.25rem;
}
