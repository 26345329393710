#intro {
    height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 75rem;
    margin: 0 auto;
    overflow: hidden;
}

.bg {
    position: absolute;
    top: -3rem;
    z-index: -1;
    right: 0;
    object-fit: cover;
    height: 70vh;
    margin-top: 33vh;
    width: 40%;
    animation: rotateAnimation 20s linear infinite;
}

@keyframes rotateAnimation {
    from {
      transform: rotate(0deg); /* Putaran awal dari 0 derajat */
    }
    to {
      transform: rotate(360deg); /* Putaran akhir ke 360 derajat (satu putaran penuh) */
    }
}

.introContent {
    height: 100vh;
    width: 100vw;
    padding: 2rem;
    font-size: 3rem;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hello {
    font-size: 1.75rem;
    font-weight: 100;
}

.introName {
    color: rgb(59, 127, 245);
}

.introPara {
    font-size: medium;
    font-weight: 300;
    letter-spacing: 1px;
}

.btn {
    background: white;
    margin: 1rem 0;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 2rem;
    transition: all 0.3s ease;
}

.btn:hover {
    background: rgb(59, 127, 245); /* Warna latar berubah saat dihover */
    color: white; /* Warna teks berubah saat dihover */
    transform: scale(1.1); /* Membesarkan tombol saat dihover */
    cursor: pointer;
}

.btnImg {
    object-fit: cover;
    margin: 0 0.25rem;
    height: 1rem;
}

@media screen and (max-width:840px) {
    .bg{
        right: -10vw;
        height: 27vh
    }
    .introContent{
        font-size: 10vw;
    }
    .hello{
        font-size: 4.5vw;
    }
    .introPara{
        font-size: 2vh;
    }
}

@media screen and (max-width:480px) {
    .bg{
        right: -35vw;
        top: -2vw;
        height: 40vh;
        width: 40vh;
    }
}