#works {
    margin: 0 auto;
    min-height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 60rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 1rem;
}

.worksTitle {
    margin: 1rem 0;
    font-size: 3rem;
}

.worksDesc {
    font-weight: 300;
    font-size: 1rem;
    max-width: 45rem;
}

.worksImgs {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    max-width: 65rem;
}

.worksImg{
    object-fit: cover;
    height: 15rem;
    margin: 0.5rem;    
    position: relative;
    transition: transform 0.3s ease-in-out;
    z-index: 1;
}

.worksImg:hover {
    transform: scale(1.1); 
    z-index: 2;
}

.worksImg:hover::after {
    content: "View Details"; 
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(255, 255, 255, 0.8); 
    padding: 0.5rem;
    border-radius: 0.5rem;
    font-size: 0.8rem;
    z-index: 3;
}

.workBtn {
    margin: 3rem 0;
    padding: 0.5rem 2.5rem;
    border: none;
    background: white;
    border-radius: 2rem;
    font-size: 1rem;
}

@media screen and (max-width:585px) {
    .worksImg{
        height: 48vw;
    }
}

.workBtn:hover {
    background: #f0f0f0; /* Change the background color on hover */
    color: #333; /* Change the text color on hover */
    cursor: pointer; /* Add a pointer cursor on hover */
  }