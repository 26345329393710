.navbar {
    background: rgb(30, 30, 30, 0.97);
    height: 5rem;
    width: 100vw;
    max-width: 95rem;
    margin: 0 auto;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 3;
}

.logo {
    object-fit: cover;
    height: 3rem;
    width: 4rem;
}

.desktopMenuListItem {
    margin: 1rem;
    cursor: pointer;
}

.desktopMenuListItem:hover {
    color: rgb(59, 127, 245);
    padding-bottom: 0.5 rem;
    border-bottom: 3px solid rgb(59, 127, 245);
}

.desktopMenuBtn {
    background: white;
    color: black;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0rem 1rem;
    border-radius: 2rem;
    transition: background 0.3s, color 0.3s, transform 0.3s;
    cursor: pointer;
}

.desktopMenuBtn:hover {
    background: rgb(59, 127, 245);
    color: white;
    transform: scale(1.05);
}

.desktopMenuImg {
    object-fit: cover;
    height: 2rem;
    width: 2rem;
    margin: 0.5rem;
}

.active {
    color: rgb(59, 127, 245);
    padding-bottom: 0.5rem;
    border-bottom: 3px solid rgb(59, 127, 245);
}

.mobMenu {
    display: none;
    object-fit: cover;
    height: 1.8rem;
}

.navMenu {
    position: absolute;
    top: 4rem;
    right: 2rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    height: fit-content;
    max-width: 15rem;
    background: rgb(40,40,40);
    border-radius: 1rem;
}

.listItem {
    color: white;
    padding: 0.5rem 3rem;
    margin: 0.25rem;
    background: rgb(30,30,30);
}

@media screen and (max-width:720px) {
    .mobMenu {
        display: flex;
    }

    .desktopMenu {
        display: none;
    }

    .desktopMenuBtn {
        display: none;
    }
}